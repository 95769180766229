













































































































































































































































































	import Vue from "vue"
	import Component from "vue-class-component"
	import { State, namespace } from "vuex-class"
	import { Watch } from "vue-property-decorator"

	import { StoreAction, StoreMutation } from "lib/types/vuex"
	import SecuredMaintenance from "api/security/SecuredMaintenance"
	import { session } from "api/request"
	import diff, { Diff } from "lib/date/diff"

	import { CommunicationDocument, PollClaim } from "store/communication/types"
	import { Policy, Claim } from "store/policies/types"
	import { CustomerState } from "store/customer/types"
	import { Message as MessageInterface, FAQ } from "api/models/communications"

	import Page from "custom/Page.vue"
	import Card from "components/panel/Card.vue"
	import Message from "components/widget/Message.vue"
	import Notice from "components/supplemental/Notice.vue"
	import AccordionList from "custom/AccordionList.vue"
	import AccordionItem from "custom/AccordionItem.vue"
	import RequestLoader from "custom/RequestLoader.vue"
	import InformationLink from "custom/InformationLink.vue"
	import InformationModal from "leven-shared-components/InformationModal.vue"
	import CommunicationPreferenceModal from "custom/modal/CommunicationPreferenceModal.vue"
	import Welcome from "custom/Welcome.vue"

	import * as rules from "store/rules"

	import eventBus from "lib/vue/eventBus"

	import { myAccountUrl } from "api/settings"
	import ProtectedButton from "leven-shared-components/ProtectedButton.vue"
	import { AnnuityExpirationDestination } from "store/routing/types"
	import { tealiumInteractionClickableLink, tealiumInteractionOtherAccordion } from "tracking/events_v4"

	const policies = namespace("policies")
	const communication = namespace("communication")
	const routing = namespace("routing")

	@SecuredMaintenance(session)
	@Component({
		components: {
			Page,
			ProtectedButton,
			Card,
			Message,
			Notice,
			AccordionList,
			AccordionItem,
			RequestLoader,
			Welcome,
			InformationLink,
			InformationModal,
			CommunicationPreferenceModal
		}
	})
	export default class Index extends Vue {
		@State userIdentifier?: string
		@State customer!: CustomerState
		@State impersonation!: boolean
		@communication.Getter documents!: Array<CommunicationDocument>
		@routing.Mutation selectDocumentId!: StoreMutation<string>
		@routing.Mutation selectWidget!: StoreMutation<"addAfkoop" | "addExpiratie" | "complete" | undefined>
		@routing.Mutation selectPolicy!: StoreMutation<Policy | undefined>
		@routing.Mutation selectClaim!: StoreMutation<Claim | undefined>
		@routing.Mutation selectProfileItem!: StoreMutation<string | undefined>
		@routing.Mutation initChangeCommunicationPreference!: StoreMutation<boolean>
		@routing.Mutation setCommunicationPreferenceAcquired!: StoreMutation<boolean>
		@routing.Mutation setPayoutToPledgeHolderRequested!: StoreMutation<boolean>
		@routing.Mutation setAnnuityExpirationDestination!: StoreMutation<AnnuityExpirationDestination>
		@routing.State communicationPreferenceAcquired!: boolean
		@communication.State customerLoaded!: boolean
		@communication.State policyLoaded!: boolean
		@communication.State claimLoaded!: boolean
		@communication.State pollClaim!: PollClaim
		@communication.State messages!: Array<MessageInterface>
		@communication.State generalFaqs!: Array<FAQ>
		@policies.Action pollClaimCommunication!: StoreAction<PollClaim>
		@policies.State policies!: Array<Policy>
		@policies.State claims!: Array<Claim>
		@policies.State policiesLoaded!: boolean
		@policies.State claimsLoaded!: boolean
		@policies.Getter currentUserCoPolicyHolderPolicies: boolean | undefined

		myAccountUrl = myAccountUrl

		showCoPolicyHolderMessage = false
		clickedPolicy = ""
		clickedClaim = ""
		clickedFAQ = ""
		clickedMessage = ""
		isEditing = true

		tealiumTitle = "Dashboard"

		get sortedClaims() {
			return [...this.claims].sort((a, b) => a.claimNumber > b.claimNumber ? -1 : 1)
		}

		get policiesInNeedOfAttention() {
			const policyInNeedOfAttention = (policy: Policy): boolean => !policy.currentUserIsCoPolicyHolder
				? this.hasOpenOrPendingRedemptionClaim(policy)
					? true
					: this.hasOpenSystemExpirationClaim(policy) || this.isExpiring(policy, 6) || this.isPending(policy)
						|| (rules.hasExpirationClaim(policy) && this.hasOpenAdvisorClaim(policy)) && !this.isPledgedPolicy(policy)
				: false

			return this.policies.filter(policyInNeedOfAttention)
		}

		get acquireCommunicationPreference() {
			const today = new Date()
			today.setHours(0, 0, 0, 0)
			const changeDate = this.customer.communicationPreferenceChangeDate || new Date()
			changeDate.setHours(0, 0, 0, 0)
			const daysSinceLastChange = diff(Diff.DAYS, changeDate, today)
			const THREE_MONTHS_AGO = 90

			return this.customer.onlineCommunication || this.communicationPreferenceAcquired ? false :
			 this.customer.previousLogin === undefined || daysSinceLastChange > THREE_MONTHS_AGO
		}

		get packagePoliciesToRedeem() {
			const isPackageRiskPolicyToRedeem = (policy: Policy): boolean => !policy.currentUserIsCoPolicyHolder
				? this.isPackageRiskPolicyToRedeem(policy)
				: false

			return this.policies.filter(isPackageRiskPolicyToRedeem)
		}

		get tealiumPageInfo() {
			return { virtualPage: { name: this.tealiumTitle } }
		}

		togglePolicy(open: boolean, clickedPolicy: string) {
			this.showCoPolicyHolderMessage = false
			this.clickedPolicy = clickedPolicy
			tealiumInteractionOtherAccordion(this.tealiumPageInfo, open, "uw levensverzekeringen")
		}

		toggleClaim(clickedClaim: string) {
			this.clickedClaim = clickedClaim
		}

		toggleFAQ(open: boolean, title: FAQ["title"], clickedFAQ: string) {
			this.clickedFAQ = clickedFAQ
			tealiumInteractionOtherAccordion(this.tealiumPageInfo, open, title)
		}

		toFAQPage(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, (event.target as HTMLLinkElement).href)
		}

		toggleMessage(open: boolean) {
			tealiumInteractionOtherAccordion(this.tealiumPageInfo, open, "actueel")
		}

		toContactPage(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, (event.target as HTMLLinkElement).href)
		}

		toProfilePage(event: MouseEvent) {
			this.initChangeCommunicationPreference(false)
			this.selectProfileItem("Basisgegevens")

			const targetLink = "profile.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		detailPolicy(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)

			const targetLink = "policydetail.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		mutatePolicy(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)

			const targetLink = "mutatieform.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		detailClaim(claim: Claim, event: MouseEvent) {
			this.selectClaim(claim)

			if (claim.hasBeenCancelled) {
				const targetLink = "statusoverview.html"
				tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

				location.href = targetLink

			} else {
				const isExcludedClaim: boolean = claim.listOfMyPolicies[0].damageCause.toLowerCase() === "overlijden"
				const policyByClaim = this.policies.find(policy =>
					policy.policyIdentifier === this.cleanPolicyNumber(claim.listOfMyPolicies[0].policyIdentifier)
				)

				if (policyByClaim && (this.hasOpenSystemExpirationClaim(policyByClaim) || this.hasOpenAdvisorClaim(policyByClaim))) {
					this.completeClaim(policyByClaim!, event)

				} else if (isExcludedClaim || this.isExcludedFromPayoutProcess(policyByClaim!)) {
					const targetLink = "statusoverview.html"
					tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

					location.href = targetLink
				} else {
					const targetLink = "payoutprocess.html"
					tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

					location.href = targetLink
				}
			}
		}

		startExpiration(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)
			this.selectWidget("addExpiratie")

			const targetLink = this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		redeemPackageAccrualPolicy(policy: Policy) {
			this.selectPolicy(policy)
			this.selectWidget("addAfkoop")

			const infoText = `Uw verzekering maakt deel uit van een pakket. Dit pakket zorgt er voor dat uw levensverzekeringen één geheel vormen. U kunt deze overlijdensrisicoverzekering stopzetten nadat u de verzekering met polisnummer ${policy.policyIdentifier} heeft stopgezet. Regel <a href=${this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"}>hier</a> het stopzetten van uw verzekering met polisnummer ${policy.policyIdentifier}.`
			eventBus.emit("informationModal", "Stopzetten pakket van levensverzekeringen", "", infoText)
		}

		startCommute(policy: Policy, event: MouseEvent) {
			const activeRelatedAccrualPolicy = this.getActivePackageAccrualPolicy(policy)

			if (activeRelatedAccrualPolicy) {
				this.redeemPackageAccrualPolicy(activeRelatedAccrualPolicy)
			} else {
				this.selectPolicy(policy)
				this.selectWidget("addAfkoop")

				const targetLink = this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"
				tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

				location.href = targetLink
			}
		}

		completeClaim(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)
			this.selectWidget("complete")

			const targetLink = this.isExcludedFromPayoutProcess(policy) ? "widget.html" : "payoutprocess.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		policyDocuments(policy: Policy, event: MouseEvent) {
			this.selectPolicy(policy)

			const targetLink = this.isExcludedFromPayoutProcess(policy) ? "documents.html" : "payoutprocess.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink)

			location.href = targetLink
		}

		goToMyAccount(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, myAccountUrl)

			window.location.href = myAccountUrl
		}

		getActivePackageAccrualPolicy(item: Policy) {
			const isActivePackageAccrualPolicy = (policy: Policy): boolean =>
				this.isPackageRiskPolicy(item)
					? policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy) && this.isRedeemable(policy)
					: false

			return this.policies.find(isActivePackageAccrualPolicy)
		}

		getRedeemedPackageAccrualPolicy(item: Policy) {
			const isRedeemedPackageAccrualPolicy = (policy: Policy): boolean =>
				this.isPackageRiskPolicy(item)
					? policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy) && !this.isRedeemable(policy) &&
						this.isPackageAccrualPolicyRedeemed(policy)
					: false

			return this.policies.find(isRedeemedPackageAccrualPolicy)
		}

		hasExpiredPackageAccrualPolicy(item: Policy) {
			const isExpiredPackageAccrualPolicy = (policy: Policy): boolean =>
				this.isPackageRiskPolicy(item)
					? policy.packageNumber === item.packageNumber && rules.packageAccrualPolicy(policy)
						&& (rules.hasExpirationClaim(policy) && !policy.claim?.hasBeenCancelled)
					: false

			return this.policies.some(isExpiredPackageAccrualPolicy)
		}

		hasOpenSystemExpirationClaim(item: Policy) {
			return rules.hasOpenSystemExpirationClaim(item)
		}

		isRedeemable(item: Policy) {
			return rules.redeemable(item)
		}

		canExpire(item: Policy) {
			return rules.canExpire(item)
		}

		isExpiring(item: Policy, period: 6 | 9) {
			return rules.expiresSoon(item, period)
		}

		isPledgedPolicy(item: Policy) {
			return rules.pledgedPolicy(item)
		}

		isLifeCourseSavingsScheme(item: Policy) {
			return rules.lifeCourseSavingsScheme(item)
		}

		isPending(item: Policy) {
			return rules.pendingClaim(item)
		}

		hasOpenOrPendingExpirationClaim(item: Policy) {
			return rules.hasOpenOrPendingExpirationClaim(item)
		}

		hasPendingExpirationClaim(item: Policy) {
			return rules.pendingClaim(item) && rules.hasExpirationClaim(item)
		}

		hasOpenOrPendingRedemptionClaim(item: Policy) {
			return rules.hasOpenOrPendingRedemptionClaim(item)
		}

		hasPendingRedemptionClaim(item: Policy) {
			return rules.pendingRedemptionClaim(item)
		}

		hasOpenAdvisorClaim(item: Policy) {
			return rules.hasOpenAdvisorClaim(item)
		}

		isPackageRiskPolicy(item: Policy) {
			return rules.packageRiskPolicy(item)
		}

		isPackageRiskPolicyToRedeem(item: Policy) {
			const redeemedRelatedAccrualPolicy = this.getRedeemedPackageAccrualPolicy(item)

			return redeemedRelatedAccrualPolicy ?
				rules.activePackageRiskPolicy(item) && !this.hasActiveClaim(item)
			: false
		}

		hasActiveClaim(item: Policy) {
			return item.claim && !item.claim.hasBeenCancelled
		}

		isPackageAccrualPolicyRedeemed(item: Policy) {
			return rules.isPackageAccrualPolicyRedeemed(item)
		}

		isPolicyEndedNoClaimYet(item: Policy) {
			return rules.policyEndedNoClaimYet(item)
		}

		isExcludedFromPayoutProcess(item: Policy) {
			return rules.isExcludedFromPayoutProcess(item)
		}

		changePolicyIsAllowed(item: Policy) {
			return rules.changePolicyAllowed(item)
		}

		documentDetail(document: CommunicationDocument, event: MouseEvent) {
			this.selectDocumentId(document.externalIdentifier)

			const targetLink = "communicationdetail.html"
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, targetLink, "bekijk bericht")

			location.href = targetLink
		}

		toDocumentsPage(event: MouseEvent) {
			tealiumInteractionClickableLink(this.tealiumPageInfo, "internal", event, (event.target as HTMLLinkElement).href)
		}

		cleanPolicyNumber(policyNumber: string) {
			return policyNumber.includes("onderdeel") ? policyNumber.split(" ")[0] : policyNumber
		}

		claimCause(cause: string) {
			return cause === "Afkoop" ? "Voortijdig stopzetten" : cause
		}

		checkOnePolicy() {
			return this.policies.length === 1
		}

		checkOneClaim() {
			return this.claims.length === 1
		}

		async mounted() {
			const script = document.createElement('script')
			script.type = "text/javascript"
			script.innerHTML = "var utag_data = { page_type: 'portal', site_business_line: 'leven', product_name: 'leven portaal'}"
            document.head.appendChild(script)
			
			await this.pollClaimChanged()
			this.selectPolicy(undefined)
			this.selectClaim(undefined)
			this.selectWidget(undefined)
			this.selectDocumentId("")
			this.setPayoutToPledgeHolderRequested(false)
			this.setAnnuityExpirationDestination(AnnuityExpirationDestination.UNDEFINED)
		}

		@Watch("pollClaim")
		async pollClaimChanged() {
			if (this.pollClaim.poll) {
				await this.pollClaimCommunication(this.pollClaim)
			}
		}
	}
